<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">操作提示</template>
        <div class="warn">
          <ul style="padding-inline-start: 20px;">
            <li>每个活动可添加2到6个商品</li>
            <li>凡选择指定优惠的商品,在这个商品的详细页将出现发布的优惠套餐</li>
            <li>特殊商品不能参加该活动</li>
          </ul>
        </div>
      </el-collapse-item>
    </el-collapse>
    <el-form style="margin-top: 30px" ref="form" label-width="130px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="优惠套餐名称" prop="name">
        <el-tooltip placement="top">
          <div slot="content">请认真填写组合优惠套餐名称,使顾客能从名称了解该套餐</div>
          <el-input v-model="form.name" style="width: 25%" size="small"/>
        </el-tooltip>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="套餐商品" prop="">
        <el-table :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="优惠套餐价格" prop="price">
        <el-input
            v-model="form.price"
            type="number"
            size="small"
            step="0.1"
            :min="0.1"
            style="width:25%"
            placeholder="请输入价格"
            @change="getInputNum"
        >
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="原价" prop="original_cost">
        {{ form.original_cost }} 元
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="节省价">
        {{ save_cost }} 元
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="运费承担" prop="is_freight">
        <el-radio-group v-model="form.is_freight">
          <el-radio :label="0">卖家承担运费</el-radio>
          <el-radio :label="1">买家承担运费(快递)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="是否上下架" prop="state">
        <el-switch
            v-model="form.state"
            active-color="#13ce66"
            active-text="上架"
            :active-value="1"
            inactive-color="#ff4949"
            inactive-text="下架"
            :inactive-value="0"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";
import {apiGetPackageInfo, apiUpdateGoodsPackage} from "@/request/api";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPop: false,
      selectRows: '',
      s_list: [],
      list: [],
      save_cost: 0,
      //
      activeName: ["1"],
      form: {
        id: '',
        name: '',
        price: '',
        original_cost: 0,
        is_freight: 0,
        state: 1,
        goods: [],
      },
      rules: {
        name: [
          {required: true, trigger: 'blur', message: '套餐名称不能为空'},
        ],
        price: [
          {required: true, trigger: 'blur', message: '套餐价格不能为空'},
          {min: 0.1, message: '价格不能小于0.1', trigger: 'blur'},
        ],
        original_cost: [
          {required: true, trigger: 'blur', message: '套餐原价不能为空'},
        ],
      },
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getPackageInfo()
    }
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    getPackageInfo() {
      apiGetPackageInfo({id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          this.form = {
            id: res.data.id,
            name: res.data.name,
            price: res.data.price,
            original_cost: res.data.original_cost,
            is_freight: res.data.is_freight,
            state: res.data.state,
            goods: this.getGoods(res.data.sku_info),
          }
          this.save_cost = res.data.original_cost - res.data.price
        }
      })
    },
    getGoods(arr) {
      this.list = []
      if (arr.length > 0) {
        arr.forEach(item => {
          this.list.push(item.skuid)
          this.s_list.push(item)
        })
        return this.list
      }
    },
    getInputNum(event) {
      console.log(event)
      if (this.s_list.length > 0) {
        this.save_cost = this.form.original_cost - event
      } else {
        this.$message.error('请选择商品')
      }
    },
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            skuid: item.skuid,
            imgUrl: item.gallery,//商品图片
            name: item.name,//商品名
            price: item.price,//价格
            stock: item.stock,//库存
          })
          this.form.original_cost += item.price
        })
        const smap = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !smap.has(key.skuid) && smap.set(key.skuid, 1))
        this.listLoading = false
      }
    },
    //删除选中商品
    handleDelete(row) {
      if (row.skuid) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.skuid == row.skuid) {
              console.log(val.price)
              this.s_list.splice(i, 1)
              this.form.original_cost -= val.price
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.skuid == this.selectRows[j].skuid) {
                this.s_list.splice(i, 1)
                this.form.original_cost -= val.price
              }
            })
          }
        })
      }
    },
    //清空表单
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.s_list = []
      this.save_cost = 0
    },
    async submit() {
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.form.goods.push(item.skuid)
        })
      } else {
        this.$message.error('套餐商品不能为空！')
        return
      }
      if (this.form.price <= 0) {
        this.$message.error('套餐价格必须大于0！')
        return
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          apiUpdateGoodsPackage(this.form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.close()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false
        }
      })
    },
  }
}

</script>
<style>
.warn {
  background-color: #fdf6ec;
  color: #e6a23c;
  padding: 8px 16px;
  font-size: 13px;
}
</style>
